<template>
	<!-- 个人用户绑卡 -->
	<div v-loading="loading" :class="themeClass" class="contBox width-fill bg-fff margin-t-10">
		<div class="form-Box">
			<div class="title flex-row-align-center text-center">
				<i class="iconfont icon-biaodan font-size30"></i>
				<div class="font-size16">{{ title }}</div>
			</div>
			<el-form :model="ruleForm" label-width="130px" class="demo-ruleForm">
				<el-form-item label="选择银行卡" prop="fBankName">
					<el-select v-model="fAccountID" placeholder="选择银行卡" @change="selectChange()">
						<el-option v-for="(item, index) in selectList" :key="index" :label="item.fAccountNumber" :value="item.fAccountID"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="持卡人姓名">
					<el-input v-model="ruleForm.fCustomerName" :disabled="true"></el-input>
				</el-form-item>
				<el-form-item label="身份证号">
					<el-input v-model="ruleForm.fIdentityCode" :disabled="true"></el-input>
				</el-form-item>
				<el-form-item label="银行卡号" prop="fAccountNumber">
					<el-input v-model="ruleForm.fAccountNumber" :disabled="true"></el-input>
				</el-form-item>
				<el-form-item label="开户行" prop="fBankName">
					<el-input v-model="ruleForm.fBankName" :disabled="true"></el-input>
				</el-form-item>
				<el-form-item label="银行预留号码" prop="fPhone">
					<el-input v-model="ruleForm.fPhone" :disabled="true"></el-input>
				</el-form-item>
				<el-form-item label="">
					<div class="tip font-color-FF9900 margin-b-10">*绑定的提现账户必须是您的支付卡中的其中一张。</div>
				</el-form-item>
				<el-form-item>
					<el-button class="background-color-theme" v-loading='buttonLoading' @click="save">保存</el-button>
				</el-form-item>
			</el-form>

		</div>
	</div>
</template>

<script>
	import {
		mapGetters
	} from "vuex";
	export default {
		data() {

			return {
				ruleForm: {},
				selectList: [],
				fAccountID: '',
				loading: true,
				title: '绑定提现卡',
				buttonLoading: false,
			};
		},
		computed: {
			...mapGetters(['getThemeName']),
			themeClass() {
				return `theme-${this.getThemeName}`;
			},
			...mapGetters(['getUserInfo']),
		},
		created() {
			this.type = this.$route.query.type
		},
		mounted() {
			this.getList();
		},
		methods: {
			selectChange() {

				for (let item of this.selectList) {
					if (this.selectList.some(p => p.fAccountID == this.fAccountID)) {
						this.ruleForm = item;
					}
				}
			},
			save() {
				if (this.fAccountID == '') {
					return this.$message('暂无可选银行卡');
				}
				this.buttonLoading = true;
				let fBranchID = ''
				this.selectList.forEach(item => {
					if (item.fAccountID == this.fAccountID) {
						fBranchID = item.fBranchID;
					}
				})
				this.ApiRequestPost("/api/mall/ebcustomer/bank-account/create-acs-b2c-withdraw-account", {
						fAccountID: this.fAccountID,
						fBranchID: fBranchID,
					})
					.then((res) => {
						this.buttonLoading = false;
						if (res.obj) {
							this.$router.push({
								path: '/businessme/allAccount',
							})
						}
					})
			},
			getList() {

				//获取充值卡
				this.ApiRequestPost(
					"/api/mall/ebcustomer/bank-account/get-acs-physical-recharge-account-list"
				).then(
					(result) => {
						if (result == null || result.obj.length == 0) {
							//提示用户没有可绑定的提现卡
							this.loading = false;
							return this.$message('暂无可选银行卡');

						}
						//获取提现卡
						this.ApiRequestPost("/api/mall/ebcustomer/bank-account/get-acs-physical-withdraw-account-list")
							.then((res) => {
								if (result) {
									if (result.obj) {
										result.obj.forEach((val) => {
											if (!res.obj.some(p => p.fAccountNumber == val.fAccountNumber)) {
												this.selectList.push(val);

												if (this.fAccountID == '') {
													this.fAccountID = val.fAccountID;
												}
											}
										});
										if (this.selectList.length == 1) {
											this.ruleForm = this.selectList[0];
										}
									}
								}
								this.loading = false;
							})
					},
					(rej) => {}
				);
			}
		}
	}
</script>

<style lang="scss" scoped="scoped">
	.color-theme {
		@include themify($themes) {
			color: themed("themes_color");
		}
	}

	.background-color-theme {
		@include themify($themes) {
			background-color: themed("themes_color");
			color: themed("text_color_white");
		}
	}

	/deep/ .tip {
		height: 16px;
		line-height: 16px;
	}

	.codeRlBox {
		height: 30px;
		line-height: 30px;
	}

	.icon-biaodan {
		color: #1c9dff;
	}

	/deep/ .el-form-item__label {
		padding-right: 19px;
	}

	/deep/ .el-input__inner {
		width: 380px;
		height: 32px;
		line-height: 32px;
		padding: 0 10px;
		border: 1px solid #ccc !important;
	}

	.sendCode {
		margin-right: 40px;
	}

	.title {
		height: 20px;
		line-height: 20px;
		margin: 55px auto 16px;
		margin-left: 384px;
	}

	.contBox {
		overflow: hidden;
	}

	.demo-ruleForm {
		// width: 462px;
		margin: 0px auto;
		margin-left: 200px;
		position: relative;
		z-index: 1;
	}

	.bg-box {
		position: relative;
		z-index: 0;
		width: 100%;
		height: 230px;
		background: url(../../../assets/imgs/u305.png) no-repeat;
		background-size: 911px 310px;
		background-position: right bottom;
		-webkit-filter: blur(1px);
		margin-top: -105px;
		opacity: 25%;
	}

	.el-button {
		width: 160px;
		height: 40px;
	}
</style>
